.custom-searchbox {
}

.custom-searchbox .hits-wrapper {
  position: relative;
  width: 100%;
}

.custom-searchbox .hits-wrapper .hits {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: +10;
  max-height: 600px;
  overflow-y: scroll;
}
.custom-searchbox .hits-wrapper .no-results {
  margin: 0.5em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}
.custom-searchbox .hits-wrapper .hits ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-searchbox .hits-wrapper .hits ul li {
  padding: 5px;
  margin: 0;
  display: grid;
  grid-template-columns: 54px auto auto;
  gap: 1rem;
  cursor: pointer;
  /* background-color: #4782da; */
}
.custom-searchbox .hits-wrapper .hits ul li:hover,
.custom-searchbox .hits-wrapper .hits ul li.highlighted {
  color: white;
  background-color: #4782da;
}
.custom-searchbox .hits-wrapper .hits ul .image {
  background-color: white;
  padding: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  border-radius: 4px;
}
.custom-searchbox .hits-wrapper .hits ul .info {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.custom-searchbox .hits-wrapper .hits ul p {
  padding: 0;
  margin: 0;
}
.custom-searchbox .hits-wrapper .hits ul p.title {
  /* font-size: large; */
  font-weight: 700;
}
.custom-searchbox .hits-wrapper .hits ul p.variant {
  font-size: smaller;
}
.custom-searchbox .hits-wrapper .hits ul p.vendor {
  font-size: smaller;
}
.custom-searchbox .hits-wrapper .hits ul .chips {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.edit-recommendations-form .products .product h3 {
  margin: 0;
  padding: 0;
}
.edit-recommendations-form .products .product p {
  margin: 0;
}
.authoring dl {
  display: grid;
  grid-template-columns: max-content auto;
}
.authoring dt {
  grid-column-start: 1;
  font-weight: bold;
  min-width: 5em;
}
.authoring dd {
  grid-column-start: 2;
  margin: 0;
}

.shareable-link {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.shareable-link > button {
  margin: 8px 0;
  min-width: 8em;
}

.authors {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2em 0 0;
  gap: 0.2em;
}
.authors .author {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
}
.authors .author:hover {
  background-color: #4782da;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.products .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overview .name-link {
  color: inherit;
  text-decoration: none;
}
.overview .name-link:focus,
.overview .name-link:hover {
  text-decoration: underline;
}
